.shy-form-controls-row{
    display: flex;
    direction: row;
    margin-top: 12px;
}
.shy-form-controls-time{
    width: 50%;
    margin-top: 0%;
}
.shy-form-controls-time:not(:last-child){
    margin-right: 5%;
}
.padding{
    padding: 3px;
}
.section-title{
    margin-top: 40px;
}

.dialog-tag{
    z-index: 0;
}