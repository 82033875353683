.shy-titlebar-wrapper {
    z-index: 100;
    position: relative;
    background-color: #ef404b;
    font-family: Roboto,sans-serif!important;
    .shy-titlebar {
      z-index: 100;
      width: 100%;
      height: 56px;
      display: flex;
      min-height: 56px;
      position: relative;
      align-items: center;
      justify-content: space-between;
      .shy-titlebar-title {
        color: #FFFFFF;
        font-size: 21px;
        font-weight: bold;
      }
      .shy-form-field {
        width: 240px;
        .shy-form-field-input {
          height: 32px;
          line-height: 32px;
        }
      }
      .shy-btn-default {
        color: #757575;
        background-color: #FFFFFF;
      }
    }
    .shy-titlebar {
      &:last-of-type {
        display: none;
      }
      &:first-of-type {
        display: flex;
      }
    }
  }
  .shy-shadow-2,
.shy-shadow-h2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}
.shy-z-idx-2 { z-index: 100 !important }
.shy-flx-vc {
  display: flex;
  align-items: center;
}
.shy-mh-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.icon-add-task{
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 40px;
    font-size: 14px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
  @media (max-width: 650px) {
    .shy-titlebar-wrapper {
      .shy-titlebar {
        &:last-of-type {
          display: flex;
        }
      }
    }
  }
  
  .shy-titlebar-right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }