.shy-form-control-input {
  height: 35px;
  outline: none;
  line-height: 24px;
  padding: 0 12px;
  width: calc(100% - 26px);
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
}
.shy-form-controls .shy-form-controls-content .shy-form-control {
  width: 100%;
  margin-bottom: 12px;
  display: inline-block;
}
.shy-form-controls-label {
  display: block;
  padding: 6px 0px;
  color: #9e9e9e;
  font-size: 12px;
}
.shy-container-wrapper .shy-pickup-container .shy-form-controls:not(:first-child) {
  margin-top: 12px;
}
.shy-container-wrapper {
  width: 100%;
  height: calc(100% - 56px);
  display: inline-block;
  background-color: #fafafa;
  overflow: auto;
}
.shy-container-wrapper .shy-pickup-container {
  width: 70%;
  margin: auto;
  padding: 16px;
  max-width: 720px;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 32px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
.pre-import .pre-import-drag-and-drop .shy-btn {
  padding: 0 16px;
  margin-top: 16px;
  border-radius: 40px;
}

.shy-btn-primary {
  color: #fff;
  background-color: #ef404b;
}
.shy-btn {
  padding: 0 16px;
  margin-top: 16px;
  border-radius: 40px;
}
.shy-btn {
  min-height: 35px;
  min-width: 95px;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  display: inline-block;
  text-transform: uppercase;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}
#pre-import {
  text-align: center;
}
.shy-flx-clmn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.shy-flx-c {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.shy-mt-16 {
  margin-top: 16px;
}
.shy-mt-12 {
  margin-top: 12px;
}
.pre-import .pre-import-drag-and-drop {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
}
.pre-import .pre-import-drag-and-drop .pre-import-drag-and-drop-area {
  display: flex;
  padding: 16px;
  margin: 0 16px;
  align-items: center;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 5px;
}
.pre-import .pre-import-drag-and-drop .pre-import-drag-and-drop-area.pre-import-droppable {
  border: 2px dashed #757575;
  border-radius: 5px;
}
.pre-import .pre-import-drag-and-drop .pre-import-drag-and-drop-area img {
  width: 80%;
}
.pre-import .pre-import-drag-and-drop .shy-btn {
  padding: 0 16px;
  margin-top: 16px;
  border-radius: 40px;
}

.shy-txt-14 {
  color: #757575;
  font-size: 14px;
}
.shy-mb-10{
  margin-bottom: 10%;
}
.shy-txt-18 {
  color: #757575;
  font-size: 18px;
}
.shy-txt-12 {
  color: #757575;
  font-size: 12px;
}
.pre-import-drag-and-drop-area.pre-import-droppable {
  border: 2px dashed #757575;
  border-radius: 5px;
}
.pre-import-drag-and-drop-area img {
    width: 30%;
    margin-top: 10%;
}
 .shy-form-control-error-icon {
  width: 12px;
  height: 12px;
  display: none;
  margin: -1px 6px 0 6px;
  vertical-align: middle;
  background-size: 100% 100%;
  background-image: url(https://cdn.shippify.co/dash/import/img/form-control-error-icon.svg);
  background-repeat: no-repeat;
  display: inline-block;
}
.shy-form-control-error {
  width: 100%;
  color: #ef404b;
  font-size: 12px;
  margin-top: 6px;
  display: inline-block;

}
.shy-form-control-error-text {
  vertical-align: top;
  display: inline-block;
  width: calc(100% - 28px);
}
.shy-loading-container {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #bdbdbd;
}
.shy-form-control-select{
  font-size: 14px;
}
.shy-loading-box {
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.spin {
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% {/* transform: rotate(0deg); */}
  100% {transform: rotate(360deg);}
}
