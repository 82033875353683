

.s-dialog {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  overflow: auto;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  animation: shy-enable-dialog-background 0.25s;
}
.tag{
  z-index: 500;
}
  .s-dialog-draggable-wrapper {
    margin: auto;;
  }
  .s-dialog-content-wrapper {
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
  }
    .s-dialog-content {
      cursor: move;
      border-radius: 5px;
      background-color: #fff;
      border: 16px solid #ffffff;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
    .s-dialog-content:hover {
      border: 16px solid #ffffff;
    }
      .s-dialog-content-toolbar {
        height: 0;
        display: flex;
        justify-content: flex-end;
      }
        .s-dialog-content-toolbar img {
          width: 20px;
          height: 20px;
          padding: 10px;
          cursor: pointer;
          margin-top: -36px;
          border-radius: 50%;
          margin-right: -36px;
          background-color: #fff;
          box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        }
      .s-dialog-content .s-dialog-header {
        color: #757575;
        font-size: 24px;
        cursor: default;
        font-weight: bold;
        margin-bottom: 16px;
      }
      .s-dialog-content .s-dialog-body {
        cursor: default;
        position: relative;
        box-sizing: border-box;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
        .s-dialog-content .s-dialog-body.s-dialog-body-sm {
          width: 360px;
        }
        .s-dialog-content .s-dialog-body .shy-txt-14 {
          word-break: break-word;
        }
        .s-dialog-content .s-dialog-body .s-dialog-body-buttons {
          display: flex;
          margin-top: 32px;
          justify-content: flex-end;
        }
        .s-dialog-content .s-dialog-body .s-dialog-body-loading,
        .s-dialog-content .s-dialog-body .s-dialog-body-success {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          margin: -16px;
          padding: 16px;
          border-radius: 5px;
          position: absolute;
          align-items: center;
          text-align: center;
          flex-direction: column;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.9);
        }
          .s-dialog-content .s-dialog-body .s-dialog-body-loading img {
            width: 80px;
            height: 80px;
          }

  .s-dialog .s-dialog-body-buttons button {
    border-radius: 40px;
  }
  .s-dialog .s-dialog-body-buttons .shy-btn {
    color: #757575;
    box-shadow: none;
  }
  .s-dialog .s-dialog-body-buttons .shy-btn:hover {
    color: #CCCCCC;
    box-shadow: none;
  }
  .s-dialog .s-dialog-body-buttons .shy-btn-primary,
  .s-dialog .s-dialog-body-buttons .shy-btn-primary:hover {
    color: #ffffff;
  }


.shy-error-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bdbdbd;
}
  .shy-error-container .shy-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
    .shy-error-container .shy-error span {
      margin-top: 20px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #626262;
    }

.shy-success-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bdbdbd;
}
  .shy-success-container .shy-success {
    display: flex;
    flex-direction: column;
  }
    .shy-success-container .shy-success span {
      margin-top: 36px;
      font-family: Roboto;
      font-size: 21px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #626262;
    }

.s-dialog-hide-background {
  animation: shy-disable-dialog-background 0.2s;
}

.s-dialog .s-dialog {
  margin: 40px;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
}

@media (max-width: 650px) {
  .s-dialog-content-wrapper {
    width: 100%;
    padding: 40px 0 !important;
  }
  .s-dialog-content {
    width: 100%;
    box-sizing: border-box;
  }
  .s-dialog-draggable-wrapper {
    width: 80%;
  }
}
