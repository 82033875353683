@tailwind preflight;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shy-container-wrapper{
  background-color: #fff !important
}
.shy-container-dropdown{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 0;
}
.pre-import-drag-and-drop-area img{
   margin-top: 0 !important;
}
.shy-btn-default{
  color:#757575;
  background-color: #fff;
}
.shy-btn-create{
  color:#fff;
  background-color: #12CE66;
}
.shy-container-btn-create{
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shy-container-login{
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.shy-container-login-wrapper{
  width: 75%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  height: 530px;
  flex-direction: row;
  display: flex;
  border-width: 1px;
  border-radius: .25rem;
  background-color: #fff;
}
.shy-login-left{
  width: 50%;
  padding: 2rem;
  flex-direction: column;
  display: flex;
}
.shy-login-left-img{
  height: 3rem;
  max-width: 100%;
  border-style: none;
}
.shy-login-left-body{
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.shy-login-left-body-container{
  margin-bottom: 2rem;
  flex-direction: column;
  display: flex;
}
.shy-login-left-body-label{
  color: #757575;
  margin-bottom: 1rem;
}
.shy-login-left-body-input{
  width: 16rem;
  font-size: .875rem;
  padding-left: 1rem;
  height: 2.5rem;
  border-width: 1px;
  border-radius: .25rem;
}
.shy-login-error{
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.shy-login-no-error{
  margin-top: -1rem;
  margin-bottom: 1rem;
  visibility: hidden;
}
.shy-login-left-space{
  margin-bottom: 50px;
}
.text-shippify{
  color: #ef404b;
}
.shy-login-left-button{
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: 700;
  align-items: center;
  flex-direction: row;
  display: flex;
  cursor: pointer;
  border-radius: .25rem;
  background-color: #ef404b;
}
.shy-login-left-button-text{
  margin-right: .5rem;
}
.shy-login-right{
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f7f7f7;
}
.shy-login-right-img{
  max-width: 100%;
  height: auto;
  border-style: none
}