.shpy-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
    display: flex;
    flex-direction: column;
}
.shy-v1-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}
.row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
.row .col.s12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}
#pending-jobs-container {
    width: 598px;
    border-radius: 4px;
    height: 240px;
}

#pending-jobs-container {
    width: 598px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 30px;
}
.card {
    position: relative;
    margin: .5rem 0 1rem 0;
    background-color: #fff;
    -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 2px;
}

.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-floating, .dropdown-content, .collapsible, .side-nav {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.card .card-content {
    padding: 10px;
    border-radius: 0 0 2px 2px;
}
.card-content {
    display: flex;
    flex-direction: column;
}
.job-rotate {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}
.card .card-content .card-title {
    display: block;
    line-height: 32px;
    margin-bottom: 0px;
    color: #444;
}
.shpy-container span {
    font-family: Roboto,sans-serif!important;
    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: #e6e6e6;
}
.shpy-container span {
    font-family: Roboto,sans-serif!important;
    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: #e6e6e6;
}

.job-wrapper {
    max-height: 400px;
}
.job-title{
    display:flex;
    justify-content: space-between;
}
.determinate{
    width: 70%;
}
.job-progress{
    position: absolute;right:25px;
}
.text{
    display:flex;
    justify-content: space-between;
}
.shpy-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
    display: flex;
    flex-direction: column;
}

.shpy-loading {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid rgba(0, 0, 0, 0.0);
    border-bottom: 5px solid rgba(0, 0, 0, 0.0);
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.shpy-container span {
    font-family: Roboto,sans-serif!important;
    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: #e6e6e6 ;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.job-title{
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    /*color:#666;*/
    height: 16px;
    font-family: Roboto,sans-serif!important;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.1px;
    text-align: right;
    color: #757575;
}
.card-text-title{
    height: 24px !important;
    font-family: Roboto,sans-serif!important;
    font-size: 21px!important;
    font-weight: bold!important;
    text-align: center!important;
    color: #757575!important;
}
.card-text-detail{
    /*height: 32px!important;*/
    font-family: Roboto,sans-serif!important;
    font-size: 14px!important;
    letter-spacing: -0.1px!important;
    text-align: center!important;
    color: #757575!important;
    width: 51%;
    margin: 12px auto 0px;
}
.card-content{
    display: flex;
    flex-direction: column;
}
.job-type{
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-family: Roboto,sans-serif!important;
    font-size: 14px;
    letter-spacing: -0.1px;
    text-align: right;
    color: #757575;
}
#pending-jobs-container{
    width: 598px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 30px;
}
.job-progress{
    font-family: Roboto,sans-serif!important;
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    letter-spacing: -0.1px;
    text-align: left;
    color: #757575;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
@keyframes spin {
100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
    }
}
.shy-report-filter-btn.pressed{
    background-color: #fff;
    color: #666;
}
.shy-titlebar-filters .active :focus{
    color: #757575 !important;
    background-color: #fff !important;
}
#tooltip-route_Single{
    line-height: 13px !important;
}
.label-line{
    line-height: 13px !important;
}

.shy-titlebar-filters{
    align-self: center !important;
    margin: 0 16px !important;
}

.shy-titlebar-filters-left {
display: flex !important;
flex-direction: row;
justify-content: center;
vertical-align: middle;
text-align: center;
}

.card .card-action {
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160,160,160,0.2);
    padding: 15px 20px !important;
}
.row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
.row:after {
    content: "";
    display: table;
    clear: both;
}
.row .col.s12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}
.row .col {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 .75rem;
    min-height: 1px;
}
#pending-jobs-container {
    width: 598px;
    border-radius: 4px;
}
.card {
    position: relative;
    margin: .5rem 0 1rem 0;
    background-color: #fff;
    -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 2px;
}
.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-floating, .dropdown-content, .collapsible, .side-nav {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.card .card-content {
    padding: 10px;
    border-radius: 0 0 2px 2px;
}
.card-content {
    display: flex;
    flex-direction: column;
}
.card .card-content .card-title {
    display: block;
    line-height: 32px;
    margin-bottom: 0px;
}
.shpy-container span {
    font-family: Roboto,sans-serif!important;
    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: #e6e6e6;
}
.card .card-action:last-child {
    border-radius: 0 0 2px 2px;
}

.card .card-action {
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160,160,160,0.2);
    padding: 16px 24px;
}
.row:after {
    content: "";
    display: table;
    clear: both;
}
.row .col.s12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}
.row .col {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 .75rem;
    min-height: 1px;
}
.card {
    position: relative;
    margin: .5rem 0 1rem 0;
    background-color: #fff;
    -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 2px;
}
.card .card-content {
    padding: 10px;
    border-radius: 0 0 2px 2px;
}
.card .card-action:last-child {
    border-radius: 0 0 2px 2px;
}

.card .card-action {
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160,160,160,0.2);
    padding: 16px 24px;
}
.progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #acece6;
    border-radius: 2px;
    margin: .5rem 0 1rem 0;
    overflow: hidden;
}
.progress .determinate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #26a69a;
    -webkit-transition: width .3s linear;
    transition: width .3s linear;
}
.card-text{
    padding-bottom: 10px !important;
}
.job-button-cancel{
    display: flex;
    justify-content: flex-end;
    margin-top: -13px;
    display: none;
}
.job-wrapper{
   max-height: 400px;
}
.job-rotate{
-webkit-animation:spin 4s linear infinite;
-moz-animation:spin 4s linear infinite;
animation:spin 4s linear infinite;
height: 70px;
}